import React from 'react';
import {useSelector} from "react-redux/es/index";
import {globalSelector} from "../../../../redux/slices/global/globalSlice";

const ContentItem = () => {
    const {singlePageArticles} = useSelector(globalSelector);

    // Filter out null, empty, and whitespace-only keywords
    const validKeywords = singlePageArticles[0]?.keywords?.filter(keyword => keyword && keyword.trim() !== "");

    return (
        <div className="content-form">
            <div className='info_list'>
                <article className='info_item'>
                    <h3 className='info_title'>{singlePageArticles[0]?.article_name}
                    </h3>
                    <h4 className='info_author'><span>By: </span>{singlePageArticles[0]?.author_names.join(', ')}</h4>
                    <span className='line'></span>
                    <div className='tb--flex tb--justify-between'>
                        <ul className='info_list tb--flex-grow '>
                            <li className='info_list--item'><span className='info_label'>Year: </span><span
                                className='info_value'>{singlePageArticles[0]?.year}</span></li>
                            <li className='info_list--item'><span className='info_label'>Volume: </span><span
                                className='info_value'>{singlePageArticles[0]?.volume}</span></li>
                            <li className='info_list--item'><span className='info_label'>Page: </span><span
                                className='info_value'>{singlePageArticles[0]?.page_begin}-{singlePageArticles[0]?.page_end}</span></li>
                            <li className='info_list--item'><span className='info_label'>DOI: </span><span
                                className='info_value'>{singlePageArticles[0]?.doi}</span></li>
                        </ul>

                        <ul className='info_list tb--flex-grow '>
                            <li className='info_list--item'><span className='info_label'>Published: </span><span
                                className='info_value'>{singlePageArticles[0]?.published}</span></li>
                            <li className='info_list--item'><span className='info_label'>Document Type: </span><span
                                className='info_value'>{singlePageArticles[0]?.document_type}</span></li>
                        </ul>
                    </div>
                    <span className='line'></span>

                    <div className='info_block-inner'>
                        <h5>Abstract</h5>
                        <p dangerouslySetInnerHTML={{__html: singlePageArticles[0]?.abstract}}></p>
                    </div>
                    <span className='line'></span>

                    <div className='info_block-inner'>
                        <h5>About the Author(s)</h5>

                        <ul className='info_list'>
                            <li className='info_list--item email-address'>
                                <span className='info_label'>Email Addresses: </span>
                                <span className='info_value'>
                                {
                                    singlePageArticles[0]?.author_emails.map((item, i)=>{
                                        return (
                                            <a href={`mailto:${item}`} key={i}>{item}</a>
                                        )
                                    })
                                }
                                </span>
                            </li>
                        </ul>

                    </div>
                    <span className='line'></span>

                    <div className='info_block-inner'>
                        <h5>Affilation(s)</h5>
                        <ul className='info_list'>
                            <li className='info_list--item'>

                                {
                                    singlePageArticles[0]?.affiliations.map((item, i)=>{
                                        return (
                                            <span className='info_label' key={i}>{item}</span>
                                        )
                                    })
                                }
                            </li>
                        </ul>


                    </div>
                    
                    <span className='line'></span> 
		    {/* Keywords Section */}
                    {validKeywords?.length > 0 && (
                        <>
                            <div className='info_block-inner'>
                                <h5>Keywords</h5>
                                <ul className='info_list'>
                                    <li className='info_list--item'>
                                        <span className='info_label'>Author Keywords: </span>
                                        <span className='info_value'>{validKeywords.join(", ")}</span>
                                    </li>
                                </ul>
                            </div>
                            <span className='line'></span>
                        </>
                    )}    

                    <div className='info_block'>
                        <ul className='info_list'>
                            <li className='info_list--item'><span className='info_label'>Language: </span><span
                                className='info_value'>{singlePageArticles[0]?.language}</span></li>
                            <li className='info_list--item'><span className='info_label'>Aknowledgment: </span><span
                                className='info_value'>{singlePageArticles[0]?.acknowledgement}</span></li>
                            <li className='info_list--item'><span
                                className='info_label'>Section</span><span className='info_value'></span>
                            </li>
                        </ul>
                    </div>

                </article>
                <article className='info_item journal_info'>
                    <h2 className='info_paragraph'>Journal information</h2>
                    <h2 className='info_sub-title'>{singlePageArticles[0]?.arm_journal_name}</h2>
                    <span className='line'></span>

                    <ul className='info_list'>
                        <li className='info_list--item'>
                            <span className='info_label'>ASCI Journal Category: </span><span className='info_value'>{singlePageArticles[0]?.ecoom_categories.join(", ")}</span>
                        </li>
                        <li className='info_list--item'><span
                            className='info_label'>ASCI Journal Research Area: </span><span className='info_value'></span>
                        </li>
                        <li className='info_list--item'><span className='info_label'>ISSN: </span><span
                            className='info_value'>{singlePageArticles[0]?.issn}</span></li>
                        <li className='info_list--item'><span className='info_label'>eISSN: </span><span
                            className='info_value'>{singlePageArticles[0]?.issn_online}</span></li>
                        <li className='info_list--item'><span className='info_label'>Publisher: </span><span
                            className='info_value'>{singlePageArticles[0]?.publisher_name}</span></li>
                    </ul>

                </article>
            </div>
        </div>
    );
};

export default ContentItem;
